<template>
   <div>
      <v-toolbar flat>
         <v-toolbar-title class="grey--text">$store.state</v-toolbar-title>
      </v-toolbar>
      <v-card><json-viewer :value="state"></json-viewer></v-card>
   </div>
</template>

<script>
export default {
   data: () => {
      return {
         state: {},
      };
   },
   mounted: function () {
      this.state = this.$store.state;
   },
};
</script>
